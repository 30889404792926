import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    showLegend: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }
        },
        legend: {
          display: this.showLegend,
          position: 'right'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              const { formatNumber } = this.$options.filters
              const allValues = chart.datasets[tooltipItem.datasetIndex].data
              const sum = allValues.reduce((partialSum, value) => partialSum + +value, 0)
              const value = +allValues[tooltipItem.index]

              const label = chart.labels[tooltipItem.index]
              const formattedValue = formatNumber(value)
              const percentage = formatNumber(value / sum * 100)
              return `${label}: ${formattedValue} (${percentage}%)`
            }
          }
        }
      }
    }
  },
  watch: {
    showLegend: {
      handler () {
        this.renderChart(this.chartData, this.options)
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
