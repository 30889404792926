<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="callingAPI" :stroke-width="5"></app-preloader-full>
      <app-editorial-performance-filter :filterType="filterType"></app-editorial-performance-filter>
      <app-kpi-articles-doughnut :isLoaded="isLoaded"></app-kpi-articles-doughnut>
      <app-users-list :isLoaded="isLoaded" :filterType="filterType"></app-users-list>
      <app-digital-articles-list :isLoaded="isLoaded"></app-digital-articles-list>
      <app-print-articles-list :isLoaded="isLoaded"></app-print-articles-list>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import EditorialPerformanceFilter from './EditorialPerformanceFilter'
import DigitalArticlesList from './components/DigitalArticlesList'
import PrintArticlesList from './components/PrintArticlesList'
import UsersList from './components/UsersList'
import KpiArticlesDoughnut from './components/KpiArticlesDoughnut'
import { mapState } from 'vuex'
import PreloaderFull from '../preloader/PreloaderFull'

export default {
  name: 'ProductPerformance',
  data () {
    return {
      filterType: 'product'
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isLoaded () {
      return this.$store.getters['editorialPerformance/loadedProduct']
    },
    filter () {
      return this.$store.getters['editorialPerformance/filter']
    }
  },
  created () {
    this.filter.department = 0
    this.$store.commit('editorialPerformance/setLoadedDepartment', false)
  },
  components: {
    appModuleView: ModuleView,
    appEditorialPerformanceFilter: EditorialPerformanceFilter,
    appDigitalArticlesList: DigitalArticlesList,
    appPrintArticlesList: PrintArticlesList,
    appUsersList: UsersList,
    appKpiArticlesDoughnut: KpiArticlesDoughnut,
    appPreloaderFull: PreloaderFull
  }
}
</script>
