<template>
  <button @click="exportExcel">
    <slot></slot>
  </button>
</template>

<script>
import XLSX from 'xlsx'
import _ from 'lodash'

export default {
  name: 'VueExcelXlsx',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    filename: {
      type: String,
      default: 'excel'
    },
    sheetname: {
      type: String,
      default: 'SheetName'
    }
  },

  methods: {
    exportExcel () {
      const createXLSLFormatObj = []
      const newXlsHeader = []
      const self = this
      if (self.columns.length === 0) {
        return
      }
      if (self.data.length === 0) {
        return
      }
      _.forEach(self.columns, function (value) {
        newXlsHeader.push(value.label)
      })

      createXLSLFormatObj.push(newXlsHeader)
      _.forEach(self.data, function (value) {
        const innerRowData = []
        _.forEach(self.columns, function (val) {
          if (val.dataFormat && typeof val.dataFormat === 'function') {
            innerRowData.push(val.dataFormat(value[val.field]))
          } else {
            innerRowData.push(value[val.field])
          }
        })
        createXLSLFormatObj.push(innerRowData)
      })

      const filename = self.filename + '.xlsx'

      const wsName = self.sheetname

      const workBook = XLSX.utils.book_new()
      const workSheet = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
      XLSX.utils.book_append_sheet(workBook, workSheet, wsName)
      XLSX.writeFile(workBook, filename)
    }
  }
}
</script>
