export const VIDEO_TYPE_NONE = 'none'
export const VIDEO_TYPE_SLIDESHOW = 'slideshow'
export const VIDEO_TYPE_AGENCY = 'agency'
export const VIDEO_TYPE_INTERNAL = 'internal'
export const VIDEO_TYPE_NATIVE = 'native'
export const VIDEO_TYPE_SOCIAL = 'social'

export const VIDEO_TYPE_DEFAULT = VIDEO_TYPE_INTERNAL

export default {
  computed: {
    kpiVideoTypeValues () {
      return [
        {
          id: VIDEO_TYPE_NONE,
          title: this.$t('video.none')
        },
        {
          id: VIDEO_TYPE_SLIDESHOW,
          title: this.$t('video.setting_slideshow')
        },
        {
          id: VIDEO_TYPE_AGENCY,
          title: this.$t('video.setting_agency')
        },
        {
          id: VIDEO_TYPE_INTERNAL,
          title: this.$t('video.setting_internal')
        },
        {
          id: VIDEO_TYPE_NATIVE,
          title: this.$t('video.setting_native_content')
        },
        {
          id: VIDEO_TYPE_SOCIAL,
          title: this.$t('video.setting_social')
        }
      ]
    }
  }
}
