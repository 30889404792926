<template>
  <div class="text-right records-options">
    {{ $t('kpi.number_of_records') }}
    <a
      @click="$emit('number-of-records', records)"
      :class="records === activeNumber && 'activeNumber'"
      v-for="(records, index) in numberOfRecordsOptions"
      :key="`item-${index}`"
    >
      {{ records }}
    </a>
  </div>
</template>

<script>
const DISPLAY_NUMBER_OPTIONS = [5, 10, 50, 100, 500]

export default {
  name: 'NumberOfRecordSelector',
  data () {
    return {
      numberOfRecordsOptions: DISPLAY_NUMBER_OPTIONS
    }
  },
  props: ['activeNumber']
}
</script>

<style scoped>
  .records-options {
    font-size: small;
  }
  .records-options a {
    margin-right: 5px;
    margin-left: 2px;
    color: #1e88e5;
  }
  .records-options a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .activeNumber {
    font-weight: bold;
  }
</style>
