<template>
  <div class="card" v-if="isLoaded">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <h3 v-if="filterType === 'product'">{{ $t('kpi.product_performance') }}</h3>
          <h3 v-if="filterType === 'department'">{{ $t('kpi.department_performance') }}</h3>
        </div>
        <div class="col-lg-6 text-right">
          <app-export-excel-xlsx
            class="btn-secondary text-right btn btn-sm"
            :data="usersMetrics"
            :columns="excelColumnsData"
            filename="vykonnost-data"
            sheetname="'vykonnost-data"
          >
            <i class="fas fa-file-excel"></i> {{ $t('kpi.base_data') }}
          </app-export-excel-xlsx>

          <app-export-excel-xlsx v-if="filterType === 'product'"
            class="btn-secondary text-right btn btn-sm"
            :data="usersPerformance"
            :columns="excelColumnsPerformance"
            filename="vykonnost-produktu"
            sheetname="vykonnost-produktu"
          >
            <i class="fas fa-file-excel"></i> {{ $t('kpi.product_performance') }}
          </app-export-excel-xlsx>

          <app-export-excel-xlsx v-if="filterType === 'department'"
            class="btn-secondary text-right btn btn-sm"
            :data="usersPerformance"
            :columns="excelColumnsPerformance"
            filename="vykonnost-oddelenia"
            sheetname="vykonnost-oddelenia"
          >
            <i class="fas fa-file-excel"></i> {{ $t('kpi.department_performance') }}
          </app-export-excel-xlsx>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-number-of-records-selector
            :activeNumber="perPage"
            @number-of-records="setRecordsNumber"
          >
          </app-number-of-records-selector>
        </div>
      </div>
      <DataTable
        :data="renderListUsers(usersCurrentPage)"
        :pagination="true"
        :total-count="usersPerformanceCount"
        :limit="perPage"
        :page="usersCurrentPage"
        :config="modifiedUsersPerformanceDtConfig"
        :actions-in-blank-window="true"
        @page-change="renderListUsers"
        :data-sorted-by="sortedBy"
        :data-sorted-order="sortedOrder"
        @data-sort-by="sortBy"
      >
        <template v-slot:first-column-slot>
          <app-select
            class="weights-id-select"
            v-model="weightsId"
            :options="weightedData"
            optionTitle="weightsName"
            optionValue="weightsId"
            :convertValue="false"
            id="weightsId"
            noEmptyValue
            :label="$t('kpi.weightsSelection')"
          />
        </template>
      </DataTable>
  </div>
</div>
</template>

<script>
import ExportExcelXlsx from '../../ExportExcelXlsx'
import Select from '../../form/select/Select'
import DataTable from '../../shared/DataTable'
import { mapState, mapGetters } from 'vuex'
import UserPerformance from '../../../model/UserPerformance'
import UserMetrics from '../../../model/UserMetrics'
import NumberOfRecordsSelector from './NumberOfRecordsSelector'

const DEFAULT_SORT_BY = 'total'
const DEFAULT_SORT_ORDER = -1
const DEFAULT_PER_PAGE_RECORDS = 5

export default {
  name: 'UsersList',
  props: ['isLoaded', 'filterType'],
  components: {
    DataTable,
    appSelect: Select,
    appNumberOfRecordsSelector: NumberOfRecordsSelector,
    appExportExcelXlsx: ExportExcelXlsx
  },
  data () {
    return {
      weightsId: '',
      usersPageToOpen: 1,
      usersCurrentPage: 1,
      usersPerformanceDtConfig: {
        fields: {
          orderNumber: this.$t('kpi.groupPerformance.orderNumber'),
          departmentName: this.$t('beUser.department'),
          userName: this.$t('beUser.name'),
          printQuality: this.$t('kpi.groupPerformance.printQuality'),
          onlineQuality: this.$t('kpi.groupPerformance.onlineQuality'),
          subscriptions: this.$t('kpi.groupPerformance.subscriptions'),
          socialEngagement: this.$t('kpi.groupPerformance.socialEngagement'),
          videoQuality: this.$t('kpi.groupPerformance.videoQuality'),
          articlesQuantity: this.$t('kpi.groupPerformance.articlesQuantity'),
          videoQuantity: this.$t('kpi.groupPerformance.videoQuantity'),
          total: this.$t('kpi.performance')
        },
        render: {
          orderNumber: value => `<b style="font-size: medium">${value}</b>`,
          userName: value => `<b style="font-size: medium">${value}</b>`,
          departmentName: value => `<b style="font-size: medium">${value}</b>`,
          printQuality: (floatVal) => this.applyBoldFormatting(floatVal),
          onlineQuality: (floatVal) => this.applyBoldFormatting(floatVal),
          subscriptions: (floatVal) => this.applyBoldFormatting(floatVal),
          socialEngagement: (floatVal) => this.applyBoldFormatting(floatVal),
          videoQuality: (floatVal) => this.applyBoldFormatting(floatVal),
          articlesQuantity: (floatVal) => this.applyBoldFormatting(floatVal),
          videoQuantity: (floatVal) => this.applyBoldFormatting(floatVal),
          total: (floatVal) => this.applyBoldFormatting(floatVal, true)
        },
        toolTip: {
          orderNumber: this.$t('kpi.toolTip.orderNumber'),
          onlineQuality: this.$t('kpi.toolTip.onlineQuality'),
          printQuality: this.$t('kpi.toolTip.printQuality'),
          subscriptions: this.$t('kpi.toolTip.subscriptions'),
          socialEngagement: this.$t('kpi.toolTip.socialEngagement'),
          videoQuality: this.$t('kpi.toolTip.videoQuality'),
          articlesQuantity: this.$t('kpi.toolTip.articlesQuantity'),
          videoQuantity: this.$t('kpi.toolTip.videoQuantity'),
          total: this.$t('kpi.toolTip.total')
        },
        overviewInfo: {
          printQuality: 0,
          onlineQuality: 0,
          subscriptions: 0,
          socialEngagement: 0,
          videoQuality: 0,
          articlesQuantity: 0,
          videoQuantity: 0
        }
      },
      sortedBy: DEFAULT_SORT_BY,
      sortedOrder: DEFAULT_SORT_ORDER,
      perPage: DEFAULT_PER_PAGE_RECORDS
    }
  },
  watch: {
    weightsId: function (val) {
      const displayWeights = this.weights.filter(obj => {
        return obj.weightsId === val
      })
      this.activateWeigts(displayWeights[0])
    }
  },
  methods: {
    applyBoldFormatting (floatVal, boldFont = false) {
      if (+this.$options.filters.formatNumber(floatVal * 100) === 100 || boldFont) {
        return `<b style="font-size: medium">${this.$options.filters.formatNumber(floatVal * 100)}</b>`
      }
      return this.$options.filters.formatNumber(floatVal * 100)
    },
    setRecordsNumber (number) {
      this.usersCurrentPage = 1
      this.perPage = number
    },
    renderListUsers (pageNumber = 1) {
      const recordsToDisplay = []
      const records = this.usersPerformance
      if (records && records.length) {
        this.usersPageToOpen = pageNumber
        for (let i = this.startUsers; i <= this.stopUsers; i++) {
          recordsToDisplay.push(records[i])
        }
        this.usersCurrentPage = this.usersPageToOpen
      }
      return recordsToDisplay
    },
    activateWeigts (weights) {
      if (weights) {
        this.$store.commit('editorialPerformance/setUsersPerformance', weights.usersPerformance)
        this.$store.commit('editorialPerformance/setUsersPerformanceCount', weights.usersPerformanceCount)
      }
    },
    sortBy (field) {
      if (field === this.sortedBy) {
        this.sortedOrder = -1 * this.sortedOrder
      }
      this.sortedBy = field
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters({
      usersMetrics: 'editorialPerformance/usersMetrics',
      weights: 'editorialPerformance/weights',
      weightedData: 'editorialPerformance/weightedData',
      productOrDepartmentWeights: 'weights/weights'
    }),
    modifiedUsersPerformanceDtConfig () {
      const processItem = (item) => {
        const zeroValueKeys = Object.keys(item).filter(key => item[key] === 0)
        const calculateValue = (keys) => keys.reduce((total, key) => total + (item[key] ?? 0), 0)

        const onlineQualityValue = calculateValue([
          'pageViewsNormalized', 'avgDailyUsers',
          'avgTimeSpentPerUserArticle', 'avgTimeSpentPerUserGallery',
          'avgArticleCompletion', 'avgGalleryCompletion',
          'avgDailyUsersOrganic'
        ])
        const socialEngagementValue = calculateValue(['fbShares', 'disqus'])
        const videoQualityValue = calculateValue(['videoPlaythrough', 'videoViews'])

        if (onlineQualityValue === 0) { zeroValueKeys.push('onlineQuality') }
        if (socialEngagementValue === 0) { zeroValueKeys.push('socialEngagement') }
        if (videoQualityValue === 0) { zeroValueKeys.push('videoQuantity') }

        return {
          zeroValueKeys,
          overviewInfo: {
            printQuality: `${item.printQuality} %`,
            onlineQuality: `${onlineQualityValue} %`,
            subscriptions: `${item.subscriptions} %`,
            socialEngagement: `${socialEngagementValue} %`,
            videoQuality: `${videoQualityValue} %`,
            articlesQuantity: `${item.articlesQuantity} %`,
            videoQuantity: `${item.videoQuantity} %`
          }
        }
      }
      const selectedItem = this.weightsId !== '' ? this.weights.find(item => item.id === this.weightsId) : this.weights[0]
      const { zeroValueKeys, overviewInfo } = selectedItem ? processItem(selectedItem) : { zeroValueKeys: [], overviewInfo: {} }

      const fieldsClone = { ...this.usersPerformanceDtConfig.fields }
      zeroValueKeys.forEach(key => {
        delete fieldsClone[key]
      })

      return {
        ...this.usersPerformanceDtConfig,
        fields: fieldsClone,
        overviewInfo
      }
    },
    excelColumnsPerformance () {
      const columns = []
      Object.keys(UserPerformance).forEach(key => {
        columns.push({
          label: this.$t('kpi.groupPerformance.' + key),
          field: key
        })
      })
      return columns
    },
    excelColumnsData () {
      const columns = []
      Object.keys(UserMetrics).forEach(key => {
        columns.push({
          label: key,
          field: key
        })
      })
      return columns
    },
    /* eslint-disable */
    usersPerformance () {
      return this.$store.getters['editorialPerformance/usersPerformance'].sort((a, b) => {
        if (a[this.sortedBy] < b[this.sortedBy]) {
          return -1 * this.sortedOrder
        }
        if (a[this.sortedBy] > b[this.sortedBy]) {
          return this.sortedOrder
        }
        return 0
      })
    },
    usersPerformanceCount () {
      return this.$store.getters['editorialPerformance/usersPerformanceCount']
    },
    startUsers () {
      return (this.usersPageToOpen - 1) * this.perPage
    },
    stopUsers () {
      if ((this.usersPerformanceCount - this.startUsers) >= this.perPage) {
        return (this.usersPageToOpen * this.perPage) - 1
      } else {
        return this.usersPerformanceCount - 1
      }
    }
  }
}
</script>

<style lang="scss">

.weights-id-select {
  position: absolute;
  top: 0;
  left: 0;

  .select-area__select-wr select {
    border: 1px solid #D1DBE4;
    background: #F2F4F8;
    color: #8490A7;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    font-weight: 700;
  }
}
</style>
